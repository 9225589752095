import * as React from 'react'

import PageLayout from 'src/layouts/page'
import Layout from '../layouts/index'
import MDXRenderer from '../components/MDX'
import SEO from '../components/SEO'

import { Template, IArticle, SiteMetaData, IMenu } from '../types'

const Page: Template = ({ pageContext, location }) => {
  const { page, siteMetaData, menu } = pageContext as {
    page: IArticle
    siteMetaData: SiteMetaData
    menu: IMenu[]
  }
  const { name } = siteMetaData

  let pagemenu = pageContext.parentMenu
    ? menu.find((m) => m.id === pageContext.parentMenu)
    : menu.find((m) => m.slug === pageContext.slug)

  if (pagemenu && (!pagemenu.children || pagemenu.children.length === 0)) {
    pagemenu = undefined
  }

  if (pagemenu !== undefined && pagemenu.children) {
    const selectedChild = pagemenu.children.find(
      (m) => m.slug === pageContext.slug
    )
    if (selectedChild) {
      selectedChild.selected = true
    }
  }

  if (!pagemenu && page.sideZoneTitle) {
    pagemenu = {
      title: page.sideZoneTitle,
      children: page.sideZone
    } as any
  }

  const parent = pageContext.parentMenu
    ? ({
        title: pagemenu?.title,
        slug: pagemenu?.slug
      } as { title: string; slug: string })
    : undefined

  return (
    <Layout
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
      menu={pageContext.menu}
      siteMetaData={pageContext.siteMetaData}
    >
      <SEO
        title={`${page.title} | ${name}`}
        canonicalUrl={page.canonical_url}
        pathname={location.href}
        description={page.excerpt || undefined}
        image={page.hero.seo.src || undefined}
        siteMetaData={pageContext.siteMetaData}
      />
      {page.full ? (
        <MDXRenderer content={page.body}>
          <span />
        </MDXRenderer>
      ) : (
        <PageLayout
          pagemenu={pagemenu}
          parent={parent}
          hero={
            page.hero && page.hero.seo && page.hero.seo.src
              ? page.hero.seo.src
              : undefined
          }
          title={page.title}
          menuTitle={pageContext.menuTitle || pageContext.title}
        >
          <MDXRenderer content={page.body}>
            <span />
          </MDXRenderer>
        </PageLayout>
      )}
    </Layout>
  )
}

export default Page

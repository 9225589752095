/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { IMenu } from 'src/types'
import classNames from 'classnames'
import { Anchor } from 'src/components'

const Banner: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <section
    className="sub-banner v1 bg-image text-left"
    id="SubBannerV2"
    data-onvisible="visible"
  >
    <picture>
      <img src={src} alt={alt} />
    </picture>
  </section>
)

const Breadcrumb: React.FC<{
  title: string
  parent?: { title: string; slug: string }
}> = ({ parent, title }) => (
  <div className="breadcrumb v2" id="BreadcrumbsButtons">
    <div className="main">
      <div
        className="bread-container flex-wrap-start-middle system-style"
        id="BreadCrumb"
      >
        <a
          aria-label="back to homepage"
          href="https://adventisthealth.org/pluskarla"
          className="home-link flex-middle-center"
        >
          <svg viewBox="0 0 36 36">
            <path d="M36 19.553v12.077a2.478 2.478 0 0 1-2.469 2.469H24.324a2.478 2.478 0 0 1-2.469-2.469v-8.477a2.478 2.478 0 0 0-2.469-2.469H16.613a2.478 2.478 0 0 0-2.469 2.469v8.477a2.478 2.478 0 0 1-2.469 2.469H2.469A2.478 2.478-64.8 0 1 0 31.634V19.574a2.463 2.463 0 0 1 0.749-1.78l15.724-15.198a2.474 2.474 0 0 1 3.461 0.02L35.252 17.793A2.418 2.418-64.8 0 1 36 19.553Z" />
          </svg>
        </a>
        <span className="separator">
          <svg viewBox="0 0 36 36">
            <path d="M29.145 19.007C29.705 18.449 29.705 17.553 29.145 16.993L12.573 0.42C12.015-0.14 11.121-0.14 10.566 0.42L6.857 4.127C6.294 4.685 6.294 5.581 6.857 6.139L18.716 18.001L6.857 29.861C6.294 30.422 6.294 31.315 6.857 31.871L10.566 35.579C11.121 36.14 12.015 36.14 12.573 35.579L29.145 19.007Z" />
          </svg>
        </span>
        {parent && (
          <>
            <Anchor
              aria-label={parent.title}
              href={parent.slug}
              className="link-style-1"
            >
              {parent.title}
            </Anchor>
            <span className="separator">
              <svg viewBox="0 0 36 36">
                <path d="M29.145 19.007C29.705 18.449 29.705 17.553 29.145 16.993L12.573 0.42C12.015-0.14 11.121-0.14 10.566 0.42L6.857 4.127C6.294 4.685 6.294 5.581 6.857 6.139L18.716 18.001L6.857 29.861C6.294 30.422 6.294 31.315 6.857 31.871L10.566 35.579C11.121 36.14 12.015 36.14 12.573 35.579L29.145 19.007Z" />
              </svg>
            </span>
          </>
        )}
        <span className="selected link-style-disabled">{title}</span>
      </div>
    </div>
  </div>
)

const SideZone: React.FC<{ pagemenu: IMenu }> = ({ pagemenu }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  return (
    <div className="icobalt isublayout sub-zone side-zone" id="SubZoneRight">
      <aside
        className={classNames('side-nav v1', { 'show-mobile': mobileOpen })}
        id="SideNavV1"
        role="menu"
      >
        <div
          className="mobile-trigger"
          onClick={(e) => {
            e.preventDefault()
            setMobileOpen(!mobileOpen)
          }}
        >
          <header>
            <h4>{pagemenu.title}</h4>
          </header>
        </div>
        <nav className={classNames({ 'show-mobile': mobileOpen })}>
          <header className="title-font no-flair">
            <strong className="title-style-5">{pagemenu.title}</strong>
          </header>
          <ul>
            {pagemenu.children?.map((menuItem) => (
              <li
                key={menuItem.slug}
                className={classNames('title-font level-1 child-4', {
                  selected: menuItem.selected
                })}
              >
                {menuItem.slug ? (
                  <Anchor href={menuItem.slug}>{menuItem.title}</Anchor>
                ) : (
                  <div>{menuItem.title}</div>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </div>
  )
}

const PageLayout: React.FC<{
  pagemenu?: IMenu
  parent?: { title: string; slug: string }
  hero: string
  title: string
  menuTitle: string
}> = ({ pagemenu, parent, hero, title, menuTitle, children }) => (
  <main id="MainZone">
    {hero && <Banner src={hero} alt={title} />}
    <Breadcrumb parent={parent} title={menuTitle} />
    <section className="two-col-container sub-zone" id="TwoColumnSubZone">
      <div className="main flex-spaced-between-top-block-1024">
        <div
          className="icobalt isublayout sub-zone content-zone"
          id="SubZoneLeft"
        >
          <div className="column-layout-content" id="ColumnLayoutContent">
            <div className="content-style" id="MainContent" data-content="true">
              {children}
            </div>
          </div>
        </div>
        {pagemenu && pagemenu.children && <SideZone pagemenu={pagemenu} />}
      </div>
    </section>
  </main>
)

export default PageLayout

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import classNames from 'classnames'
import { Anchor } from '../../components'
import type { IMenu } from '../../types'

const AccessibilityMenu: React.FC = () => (
  <div className="access-options access-menu v1" id="AccessibilityOptions">
    <div
      className="access-btn open-btn flex-middle-center"
      title="Open the accessibility options menu"
      tabIndex={0}
      role="button"
      aria-pressed="false"
      aria-expanded="false"
      aria-controls="AccessOptionsMenu"
    >
      <svg viewBox="0 0 36 36">
        <path d="M3.37 27.6L32.63 27.6C34.516 27.6 36 29.05 36 30.8C36 32.55 34.474 34 32.63 34L3.37 34C1.484 34 0 32.55 0 30.8C0 29.05 1.528 27.6 3.37 27.6ZM3.37 14.8L23.64 14.8C25.528 14.8 27.012 16.25 27.012 18C27.012 19.75 25.528 21.2 23.64 21.2L3.37 21.2C1.484 21.2 0 19.75 0 18C0 16.25 1.528 14.8 3.37 14.8ZM32.676 8.4L3.37 8.4C1.484 8.4 0 6.95 0 5.2C0 3.45 1.526 2 3.37 2L32.63 2C34.516 2 36 3.45 36 5.2C36 6.95 34.516 8.4 32.676 8.4L32.676 8.4Z" />
      </svg>
      {` `}
      <span>Open Accessibility Menu</span>
    </div>
    <div className="flex-middle-between top-info">
      <svg className="accessibility" viewBox="0 0 36 36">
        <path d="M502.7 0a84.3 84.3 0 1 1-84.3 84.3A84.6 84.6 114.1 0 1 502.7 0ZM868.6 272.7L617.5 304.2v251.4L739.2 960.7a47.2 47.2 0 0 1-34.4 57.6A46.7 46.7 114.1 0 1 647.9 987.4L522.5 616.5H484.3L369.5 993.8a47 47 0 0 1-61.6 26.7a49.1 49.1 0 0 1-29.6-61.9L383.7 560.4V304.2L152.3 272.7A41.9 41.9 114.1 0 1 114.1 226.3A43.8 43.8 114.1 0 1 161.4 186.8L442.4 210.8h123.3L865.1 186.8a43.2 43.2 0 0 1 3.5 86.5Z" />
      </svg>
    </div>
    <ul id="AccessOptionsMenu" aria-hidden="true">
      <li>
        <button
          type="button"
          className="flex-"
          data-action="content"
          aria-label=" Skip to Content"
        >
          <svg viewBox="0 0 36 36">
            <path d="M12.706 23.824L12.706 20.647L36 20.647L36 23.824L12.706 23.824ZM0 12.177L36 12.177L36 15.353L0 15.353L0 12.177ZM27.529 3.706L36 3.706L36 6.882L27.529 6.882L27.529 3.706ZM0 3.706L23.294 3.706L23.294 6.882L0 6.882L0 3.706ZM8.471 23.824L0 23.824L0 20.647L8.471 20.647L8.471 23.824ZM19.059 32.294L0 32.294L0 29.118L19.059 29.118L19.059 32.294Z" />
          </svg>
          {` `}
          Skip to Content
        </button>
      </li>
      <li>
        <button
          type="button"
          className="flex-"
          data-action="menu"
          aria-label=" Skip to Menu"
        >
          <svg viewBox="0 0 36 36">
            <path d="M7.412 31.235L7.412 28.059L36 28.059L36 31.235L7.412 31.235ZM7.412 16.412L36 16.412L36 19.588L7.412 19.588L7.412 16.412ZM7.412 4.764L36 4.764L36 7.941L7.412 7.941L7.412 4.764ZM0 28.059L4.235 28.059L4.235 31.235L0 31.235L0 28.059ZM0 16.412L4.235 16.412L4.235 19.588L0 19.588L0 16.412ZM0 4.764L4.235 4.764L4.235 7.941L0 7.941L0 4.764Z" />
          </svg>
          {` `}
          Skip to Menu
        </button>
      </li>
      <li>
        <button
          type="button"
          className="flex-"
          data-action="largeText"
          aria-label=" Increase Text Size"
        >
          <svg viewBox="0 0 36 36">
            <path d="M31.2 17.404L31.2 12.604L28.8 12.604L28.8 17.404L24 17.404L24 19.804L28.8 19.804L28.8 24.604L31.2 24.604L31.2 19.804L36 19.804L36 17.404L31.2 17.404ZM26.4 1.804L0.01 1.796L0 5.404L10.8 5.404L10.8 34.204L14.4 34.204L14.4 5.404L26.4 5.404L26.4 1.804Z" />
          </svg>
          {` `}
          Increase Text Size
        </button>
      </li>
      <li>
        <button
          type="button"
          className="flex- clear-btn"
          data-action="clear"
          aria-label="Clear All "
        >
          Clear All
          <svg viewBox="0 0 36 36">
            <path d="M21.179 14.82L21.179 29.644L23.297 29.644L23.297 14.82L21.179 14.82ZM16.939 14.828L16.939 29.653L19.057 29.653L19.057 14.828L16.939 14.828ZM12.706 14.82L12.706 29.644L14.823 29.644L14.823 14.82L12.706 14.82ZM8.468 10.587L27.53 10.587L27.53 33.88L8.468 33.88L8.468 10.587ZM6.35 6.351L29.65 6.351L29.65 8.473L28.699 8.473C28.663 8.471 28.627 8.469 28.591 8.469L7.409 8.469C7.373 8.469 7.337 8.471 7.301 8.473L6.35 8.473L6.35 6.351ZM12.706 2.118L23.297 2.118L23.294 4.233L12.706 4.233L12.706 2.118ZM11.645 0C11.365 0 11.096 0.11 10.897 0.309C10.696 0.508 10.588 0.779 10.588 1.061L10.588 4.233L5.291 4.233C5.01 4.233 4.743 4.346 4.542 4.542C4.342 4.742 4.232 5.01 4.232 5.292L4.232 9.532C4.232 10.116 4.709 10.587 5.291 10.587L6.35 10.587L6.35 34.939C6.35 35.523 6.825 36 7.409 36L28.591 36C29.175 36 29.65 35.523 29.65 34.939L29.65 10.587L30.707 10.587C31.291 10.587 31.766 10.116 31.768 9.532L31.768 5.292C31.768 5.01 31.658 4.742 31.456 4.542C31.257 4.346 30.988 4.233 30.707 4.233L25.412 4.233L25.412 1.061C25.412 0.779 25.304 0.508 25.103 0.309C24.904 0.11 24.635 0 24.353 0L11.645 0Z" />
          </svg>
        </button>
      </li>
    </ul>
    <div
      className="access-btn close-btn"
      tabIndex={0}
      role="button"
      aria-pressed="false"
      aria-expanded="false"
      aria-controls="AccessOptionsMenu"
    >
      <svg viewBox="0 0 36 36">
        <path d="M26.618 28.29L25.14 29.751L15.549 20.246L15.549 20.246L14.073 18.783L15.549 17.321L15.549 17.321L25.14 7.813L26.618 9.274L17.025 18.783L26.618 28.29ZM10.956 34.436L26.608 34.436L26.608 36L9.392 36L9.392 34.436L9.392 1.564L9.392 0L26.608 0L26.608 1.564L10.956 1.564L10.956 34.436Z" />
      </svg>
      {` `}
      <span>Hide</span>
    </div>
  </div>
)

const AlertBars: React.FC = () => (
  <div className="alert-bars">
    <div
      className="alert-bar red"
      style={{ display: `none` }}
      id="WarningAlert"
    >
      <a className="alert">Warning Alert</a>
      <button type="button" className="close" aria-label="Close Warning Alert">
        <svg viewBox="0 0 16 16">
          <polyline
            points="2 2 14 14"
            style={{
              strokeLinecap: `round`,
              strokeWidth: 2,
              strokeLinejoin: `round`,
              fill: `none`
            }}
          />
          <polyline
            points="2 14 14 2"
            style={{
              strokeLinecap: `round`,
              strokeWidth: 2,
              strokeLinejoin: `round`,
              fill: `none`
            }}
          />
        </svg>
      </button>
    </div>
    <div className="alert-bar green" style={{ display: `none` }} id="InfoAlert">
      <a className="alert">Info Alert</a>
      <button type="button" className="close" aria-label="Close Info Alert">
        <svg viewBox="0 0 16 16">
          <polyline
            points="2 2 14 14"
            style={{
              strokeLinecap: `round`,
              strokeWidth: 2,
              strokeLinejoin: `round`,
              fill: `none`
            }}
          />
          <polyline
            points="2 14 14 2"
            style={{
              strokeLinecap: `round`,
              strokeWidth: 2,
              strokeLinejoin: `round`,
              fill: `none`
            }}
          />
        </svg>
      </button>
    </div>
  </div>
)

const Logo: React.FC = () => (
  <Anchor
    className="top-logo flex-middle"
    href="https://www.adventisthealth.org/adventist-health-plus-karla/
    "
  >
    <img
      className="karla-logo"
      loading="lazy"
      alt="Adventist Health"
      src="/images/logo.png"
      title="Adventist Health"
    />
  </Anchor>
)

const TopBar: React.FC<{ tel: string }> = ({ tel }) => (
  <div className="top-bar flex-middle-end">
    <div className="flex-between-middle box-cont">
      <a className="phone-link" href={`tel:${tel}`}>
        {tel}
      </a>
      <a
        className="request-info"
        href="https://www.adventisthealth.org/adventist-health-plus-karla/contact-us/"
      >
        Request More Information
      </a>
      <a
        className="site-search popup flex-center-middle"
        title="Site Search"
        aria-label="Site Search"
        href="https://www.adventisthealth.org/site-search/"
      >
        <svg viewBox="0 0 24 24" data-use="/images/site/icon_search.24.svg">
          <path d="M4.733 9.415C4.733 8.115 5.188 7.009 6.098 6.098C7.009 5.188 8.114 4.733 9.415 4.733C10.716 4.733 11.821 5.188 12.731 6.098C13.642 7.009 14.097 8.115 14.097 9.415C14.097 10.716 13.642 11.821 12.731 12.732C11.821 13.642 10.716 14.097 9.415 14.097C8.114 14.097 7.009 13.642 6.098 12.732C5.188 11.821 4.733 10.716 4.733 9.415ZM14.829 14.097L14.536 13.805C15.609 12.569 16.146 11.106 16.146 9.415C16.146 7.529 15.495 5.928 14.195 4.611C12.894 3.294 11.301 2.636 9.415 2.636C7.53 2.636 5.928 3.294 4.611 4.611C3.294 5.928 2.636 7.529 2.636 9.415C2.636 11.301 3.294 12.894 4.611 14.195C5.928 15.495 7.53 16.146 9.415 16.146C11.106 16.146 12.569 15.609 13.805 14.536L14.097 14.829L14.097 15.658L19.267 20.827L20.828 19.267L15.658 14.097L14.829 14.097Z" />
        </svg>
      </a>
    </div>
  </div>
)

const TopBarMobile: React.FC<{
  tel: string
  open: boolean
  setOpen: (open: boolean) => void
}> = ({ tel, open, setOpen }) => (
  <div className="mobile-box flex-between-middle">
    <a className="phone-link" href={`tel:${tel}`}>
      <svg viewBox="0 0 36 36" data-use="/images/site/gp_5z706i8p.36.svg#phone">
        <path d="M35.617 30.243a9.43 9.43 0 0 1-8.38 5.744c-0.459 0-7.602 0.379-17.87-9.358-8.264-7.839-9.273-16.268-9.358-17.87-0.086-1.56 0.361-5.474 5.75-8.381 0.667-0.361 1.99-0.52 2.33-0.178 0.151 0.151 4.67 7.586 4.788 7.833a1.662 1.662 0 0 1 0.178 0.726a2.114 2.114 0 0 1-0.726 1.274a12.375 12.375 0 0 1-1.578 1.4a15.377 15.377 0 0 0-1.578 1.353a1.939 1.939 0 0 0-0.726 1.171c0.033 0.789 0.682 3.634 5.637 7.983s7.336 5.576 7.565 5.661a1.723 1.723 0 0 0 0.573 0.126a1.939 1.939 0 0 0 1.173-0.725c0.475-0.485 2.073-2.675 2.583-3.157a2.115 2.115 0 0 1 1.274-0.725a1.66 1.66 0 0 1 0.725 0.178c0.246 0.118 7.691 4.475 7.833 4.622 0.387 0.396 0.113 1.631-0.178 2.332" />
      </svg>
      <span>{tel}</span>
    </a>
    <a
      className="site-search popup flex-center-middle"
      title="Site Search"
      aria-label="Site Search"
      href="https://www.adventisthealth.org/site-search/"
    >
      <svg
        viewBox="0 0 36 36"
        data-use="/images/site/gp_5z706i8p.36.svg#search"
      >
        <path d="M20.9 0a15.069 15.069 0 0 0-14.96 15.2A15.446 15.446-5 0 0 8.5 23.7L0.718 31.6a2.6 2.6 0 0 0 0 3.59a2.434 2.434 0 0 0 3.511 0l7.7-7.819A14.67 14.67-5 0 0 20.9 30.4a15.12 15.12 0 0 0 14.96-15.2A15.069 15.069-5 0 0 20.9 0Zm0 5.067A10.134 10.134-5 1 1 10.931 15.2A10.063 10.063-5 0 1 20.9 5.067Z" />
      </svg>
    </a>
    <a
      className="menu-btn mm-open desktop"
      role="button"
      aria-label="Toggle Mobile Menu"
      data-role="btn"
      onKeyPress={(e) => {
        e.preventDefault()
        setOpen(!open)
      }}
      onClick={(e) => {
        e.preventDefault()
        setOpen(!open)
      }}
    >
      <span />
      <span />
      <span />
    </a>
  </div>
)

const NavBarChild: React.FC<{
  menuItem: IMenu
  index: number
  menuArray: IMenu[]
}> = ({ menuItem, index, menuArray }) => {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <li className=" flex-center-middle children">
      <Anchor
        className="flex-middle-center auto"
        href={menuItem.slug}
        role="menuitem"
        aria-haspopup="true"
      >
        {index === menuArray.length - 1 && (
          <svg
            viewBox="0 0 36 36"
            data-use="/images/site/gp_5z706i8p.36.svg#hamburger"
          >
            <path d="M0 32.505v-7.2h36v7.2Zm0-10.905v-7.2h36v7.2ZM0 10.695V3.495h36V10.695Z" />
          </svg>
        )}
        {menuItem.title}
      </Anchor>
      {menuItem.children && menuItem.children.length > 0 && (
        <span
          className={classNames('el-tab', { active: expanded })}
          onClick={(e) => {
            e.preventDefault()
            setExpanded(!expanded)
          }}
        >
          <svg
            viewBox="0 0 36 36"
            data-use="/images/site/gp_5z706i8p.36.svg#caret_right"
          >
            <path d="M29.145 19.007C29.705 18.449 29.705 17.553 29.145 16.993L12.573 0.42C12.015-0.14 11.121-0.14 10.566 0.42L6.857 4.127C6.294 4.685 6.294 5.581 6.857 6.139L18.716 18.001L6.857 29.861C6.294 30.422 6.294 31.315 6.857 31.871L10.566 35.579C11.121 36.14 12.015 36.14 12.573 35.579L29.145 19.007Z" />
          </svg>
        </span>
      )}
      {menuItem.children && menuItem.children.length > 0 && (
        <div
          className={classNames('fly-out el-tab-panel', { expanded })}
          role="menu"
          id="kneesNavFlyOut"
          data-role="fly-nav"
        >
          <ul className="flex-grid-wrap-block-1024 ui-scroll">
            {menuItem.children.map((menuChild) => (
              <li key={menuChild.slug} className=" level-1 full pl10">
                <Anchor href={menuChild.slug} role="menuitem">
                  {menuChild.title}
                </Anchor>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  )
}

const NavBar: React.FC<{ menu: IMenu[] }> = ({ menu }) => (
  <nav
    className="top-nav auto stretch flex-end-block-1024 el-tab-box"
    data-role="panel"
  >
    <ul
      className="desktop-nav flex-end auto"
      id="HeaderAreaV1TopNavigation"
      role="menubar"
    >
      {menu
        .filter((m) => m.menu === 'main')
        .map((menuItem, index, menuArray) => (
          <NavBarChild
            key={menuItem.slug}
            menuItem={menuItem}
            index={index}
            menuArray={menuArray}
          />
        ))}
    </ul>
  </nav>
)

const SearchForm: React.FC = () => (
  <form
    id="Form_SiteSearchPop"
    method="post"
    encType="multipart/form-data"
    action="https://adventisthealth.org"
    data-search={1}
  >
    <input type="hidden" name="_m_" defaultValue="SiteSearchPop" />
    <div
      id="SiteSearchPop"
      style={{ display: `none` }}
      className="search-box pop"
    >
      <div id="HeaderPopUp" className="header-pop flex-middle-center">
        <div className="close box" />
        <div className="popup ui-repeater" id="PopSiteSearch">
          <button
            type="button"
            className="close"
            aria-label="Close Site Search Popup"
            data-item="i"
            data-key
          >
            <svg viewBox="0 0 16 16">
              <polyline
                points="2 2 14 14"
                style={{
                  strokeLinecap: `round`,
                  strokeWidth: 2,
                  strokeLinejoin: `round`,
                  fill: `none`
                }}
              />
              <polyline
                points="2 14 14 2"
                style={{
                  strokeLinecap: `round`,
                  strokeWidth: 2,
                  strokeLinejoin: `round`,
                  fill: `none`
                }}
              />
            </svg>
          </button>
          <fieldset data-item="i" data-key>
            <legend className="ninja">Search Site</legend>
            <label htmlFor="PopSiteSearch_ITM0_C">
              <span>How can we help?</span>
            </label>
            <div className="search-input flex-center-stretch">
              <input
                placeholder="Search"
                type="search"
                id="PopSiteSearch_ITM0_C"
                name="PopSiteSearch$ITM0$C"
              />
              <button
                className="btn v1"
                type="submit"
                id="PopSiteSearch_ITM0_ctl02"
                name="PopSiteSearch$ITM0$ctl02"
                data-commandname="Search"
              >
                Search
              </button>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </form>
)

/**
 * Main Header Zone
 */
const Header: React.FC<{ menu: IMenu[]; tel: string }> = ({ menu, tel }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  return (
    <header className={mobileOpen ? 'mm-open' : undefined} id="HeaderZone">
      <AccessibilityMenu />
      <div className="header-area v1" id="HeaderAreaV1" data-section>
        <div className="sticky-box">
          <AlertBars />
          <div className="main wide flex-between">
            <Logo />
            <div className="header-box auto">
              <TopBar tel={tel} />
              <div className="nav-bar flex-middle-between">
                <NavBar menu={menu} />
              </div>
            </div>
            <TopBarMobile tel={tel} open={mobileOpen} setOpen={setMobileOpen} />
          </div>
        </div>
      </div>
      <SearchForm />
    </header>
  )
}

export default Header

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import Header from './sections/header'
import Footer from './sections/footer'
import type { IMenu, SiteMetaData } from '../types'

const TEL = '(833) 249-3556'

const FOOTER_MENU = [
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 910,
    slug:
      'https://www.adventisthealth.org/patient-resources/compliance-information/',
    title: 'Compliance Information'
  },
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 930,
    slug: 'https://www.adventisthealth.org/nondiscrimination-policy/',
    title: 'Nondiscrimination Policy'
  },
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 940,
    slug: 'https://www.adventisthealth.org/language-assistance-policy/',
    title: 'Language Assistance Policy'
  },
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 950,
    slug: '/patient-policy/',
    title: 'Privacy Policy'
  },
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 960,
    slug: '/terms-condiitions/',
    title: 'Terms & Conditions'
  },
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 970,
    slug: '/for-providers/',
    title: 'For providers'
  },
  {
    lang: 'en-US',
    menu: 'footer',
    menuSort: 990,
    slug: 'https://adventisthealth.org',
    title: 'adventisthealth.org'
  }
]

interface LayoutProps {
  location: Location
  basePath: string
  locale: string
  languages: any
  translations: any
  menu: IMenu[]
  siteMetaData: SiteMetaData
}

export const Layout: React.FC<LayoutProps> = ({ menu, children }) => (
  <>
    <div>
      <Header menu={menu} tel={TEL} />
      {children}
      <Footer menu={menu} tel={TEL} />
      <div>
        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
      </div>
    </div>
  </>
)

export default Layout

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import type { IMenu } from '../../types'
import { Anchor } from '../../components'

const FooterMainMenu: React.FC<{ menu: IMenu[] }> = ({ menu }) => (
  <nav className="footer-nav" id="FooterV1Links">
    <ul className="flex-start-wrap">
      {menu
        .filter((m) => m.menu === 'main')
        .map((menuItem) => (
          <li key={menuItem.slug}>
            <Anchor href={menuItem.slug}>{menuItem.title}</Anchor>
          </li>
        ))}
    </ul>
  </nav>
)

const FooterMenu: React.FC<{ menu: IMenu[] }> = ({ menu }) => (
  <ul className="flex-wrap-end two-thirds">
    {menu
      .filter((item) => item.menu.startsWith('footer'))
      .map((menuItem) => (
        <li key={menuItem.slug}>
          <Anchor href={menuItem.slug}>{menuItem.title}</Anchor>
        </li>
      ))}
  </ul>
)

const FooterSocial: React.FC<{ social?: any[] }> = () => (
  <ul className="social-info items-1 flex- ui-repeater" id="FooterSocial">
    <li className="item-1" data-item="i">
      <a
        className="flex-center-middle"
        title="NewsLetter"
        href="https://confirmsubscription.com/h/j/D345109D22878E39"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_newsletter_icon.36.svg "
        >
          <path d="M34.322 36L34.318 36L1.683 36C0.756 35.997 0.006 35.24 0.006 34.305L0.006 15.514L12.516 24.437L3.636 31.291C3.151 31.657 3.053 32.351 3.417 32.839C3.78 33.329 4.469 33.428 4.953 33.061L14.395 25.775L17.467 27.989C17.847 28.259 18.356 28.259 18.736 27.989L21.809 25.775L31.255 33.048C31.739 33.414 32.427 33.316 32.791 32.826C33.154 32.338 33.056 31.644 32.571 31.276L23.709 24.437L36 15.673L36 34.309C35.997 35.242 35.248 35.997 34.322 36ZM32.4 10.848L35.385 13.397L32.4 15.527L32.4 10.848ZM17.908 20.078L18.157 20.078C18.696 20.078 19.132 20.519 19.132 21.062C19.132 21.605 18.696 22.044 18.157 22.044L17.908 22.044C13.399 22.044 9.745 18.359 9.745 13.812C9.745 9.266 13.399 5.58 17.908 5.58C22.415 5.58 26.07 9.266 26.07 13.812L26.07 15.491C26.079 16.607 25.484 17.64 24.518 18.184C23.056 19.01 21.209 18.482 20.391 17.008C19.671 17.575 18.782 17.88 17.869 17.873C15.631 17.859 13.827 16.017 13.842 13.759C13.857 11.501 15.683 9.683 17.922 9.698C20.162 9.712 21.965 11.554 21.95 13.812L21.95 15.491C21.982 16.046 22.421 16.486 22.97 16.52C23.57 16.557 24.084 16.096 24.12 15.491L24.12 13.812C24.12 10.352 21.34 7.546 17.908 7.546C14.476 7.546 11.694 10.352 11.694 13.812C11.694 17.273 14.476 20.078 17.908 20.078ZM17.908 11.701C16.751 11.701 15.814 12.647 15.814 13.812C15.814 14.979 16.751 15.923 17.908 15.923C19.063 15.923 20 14.979 20 13.812C20 12.647 19.063 11.701 17.908 11.701ZM28.748 2.756C28.748 2.321 28.399 1.97 27.968 1.97L8.042 1.97C7.614 1.973 7.268 2.321 7.265 2.752L7.265 17.986L5.313 16.606L5.313 12.281L5.313 9.729L5.313 2.752C5.313 1.233 6.534 0 8.042 0L27.964 0C29.472 0 30.693 1.233 30.693 2.752L30.693 9.879L30.693 12.45L30.693 16.606L28.748 17.986L28.748 2.756ZM3.821 10.658L3.821 15.527L0.713 13.314L3.821 10.658Z" />
        </svg>
      </a>
    </li>
    <li className="item-2" data-item="i">
      <a
        className="flex-center-middle"
        title="Pinterest"
        href="https://www.pinterest.com/adventisthealth/"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_pinterest_icon.36.svg"
        >
          <path d="M18.84 0C9.052 0 4.118 7.044 4.118 12.919C4.118 16.474 5.459 19.637 8.337 20.816C8.808 21.011 9.231 20.824 9.368 20.299C9.463 19.937 9.688 19.022 9.788 18.639C9.927 18.123 9.873 17.94 9.492 17.487C8.662 16.506 8.132 15.233 8.132 13.434C8.132 8.208 12.027 3.529 18.275 3.529C23.807 3.529 26.847 6.922 26.847 11.454C26.847 17.415 24.217 22.447 20.315 22.447C18.16 22.447 16.547 20.659 17.063 18.464C17.683 15.845 18.883 13.018 18.883 11.126C18.883 9.435 17.978 8.022 16.104 8.022C13.901 8.022 12.132 10.311 12.132 13.375C12.132 15.328 12.789 16.647 12.789 16.647C12.789 16.647 10.534 26.234 10.139 27.914C9.352 31.259 10.021 35.357 10.078 35.771C10.11 36.018 10.425 36.076 10.566 35.89C10.77 35.623 13.394 32.372 14.286 29.124C14.538 28.204 15.734 23.441 15.734 23.441C16.451 24.812 18.543 26.017 20.766 26.017C27.389 26.017 31.882 19.957 31.882 11.846C31.882 5.714 26.706 0 18.84 0Z" />
        </svg>
      </a>
    </li>
    <li className="item-3" data-item="i">
      <a
        className="flex-center-middle"
        title="Instagram"
        href="https://www.instagram.com/adventisthealth/"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_instagram_icon.36.svg "
        >
          <path d="M29.813 35.994L6.188 35.994C2.771 35.994 0 33.225 0 29.81L0 14.063L6.995 14.063C6.608 15.214 6.396 16.44 6.396 17.719C6.396 24.065 11.56 29.226 17.908 29.226C24.256 29.226 29.419 24.065 29.419 17.721C29.419 16.442 29.207 15.214 28.82 14.063L36 14.063L36 29.81C36 33.225 33.23 35.994 29.813 35.994ZM25.578 17.719C25.578 21.953 22.144 25.386 17.908 25.386C13.671 25.386 10.237 21.953 10.237 17.719C10.237 13.483 13.671 10.051 17.908 10.051C22.144 10.051 25.578 13.483 25.578 17.719ZM17.908 6.213C13.488 6.213 9.646 8.715 7.716 12.377L0 12.377L0 6.193C0 3.063 2.325 0.478 5.344 0.065L5.344 7.881L6.188 7.881L6.188 0.005L6.469 0.005L6.469 7.881L7.313 7.881L7.313 0.005L7.594 0.005L7.594 7.881L8.437 7.881L8.437 0.005L8.718 0.005L8.718 7.881L9.562 7.881L9.562 0.005L29.813 0.005C33.23 0.005 36 2.777 36 6.193L36 12.377L28.098 12.377C26.17 8.715 22.327 6.213 17.908 6.213ZM32.344 6.834L32.344 3.865C32.344 3.13 31.75 2.537 31.017 2.537L28.047 2.537C27.312 2.537 26.72 3.13 26.72 3.865L26.72 6.834C26.72 7.568 27.312 8.16 28.047 8.16L31.017 8.16C31.75 8.16 32.344 7.568 32.344 6.834Z" />
        </svg>
      </a>
    </li>
    <li className="item-4" data-item="i">
      <a
        className="flex-center-middle"
        title="Twitter"
        href="https://twitter.com/AdventistHealth"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_twitter_icon.36.svg "
        >
          <path d="M11.925 32.779C25.126 32.444 32.334 21.266 32.334 11.553C32.334 11.228 32.328 10.906 32.314 10.586C33.756 9.534 35.01 8.219 36 6.724C34.674 7.316 33.251 7.718 31.757 7.898C33.282 6.974 34.453 5.511 35.005 3.769C33.577 4.624 31.996 5.245 30.314 5.579C28.966 4.129 27.046 3.221 24.922 3.221C20.841 3.221 17.534 6.566 17.534 10.686C17.534 11.271 17.6 11.841 17.726 12.387C11.585 12.076 6.143 9.104 2.499 4.589C1.863 5.693 1.499 6.974 1.499 8.341C1.499 10.931 2.803 13.215 4.785 14.552C3.574 14.515 2.435 14.18 1.438 13.62C1.437 13.651 1.437 13.683 1.437 13.715C1.437 17.33 3.984 20.346 7.364 21.032C6.745 21.202 6.091 21.295 5.418 21.295C4.942 21.295 4.479 21.247 4.028 21.16C4.968 24.126 7.696 26.283 10.93 26.342C8.401 28.344 5.216 29.537 1.755 29.537C1.161 29.537 0.574 29.503 0 29.435L0 29.438C3.154 31.48 6.88 32.696 10.885 32.779L11.925 32.779" />
        </svg>
      </a>
    </li>
    <li className="item-5" data-item="i">
      <a
        className="flex-center-middle"
        title="Facebook"
        href="https://www.facebook.com/AdventistHealth?ref=hl"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_facebook_icon.36.svg "
        >
          <path d="M20.767 36L20.767 19.578L26.24 19.578L27.059 13.178L20.767 13.178L20.767 9.092C20.767 7.24 21.278 5.977 23.916 5.977L27.282 5.976L27.282 0.251C26.699 0.175 24.701 0 22.377 0C17.527 0 14.206 2.981 14.206 8.461L14.206 13.178L8.718 13.178L8.718 19.578L14.206 19.578L14.206 36L20.767 36Z" />
        </svg>
      </a>
    </li>
    <li className="item-6" data-item="i">
      <a
        className="flex-center-middle"
        title="YouTube"
        href="https://www.youtube.com/channel/UCYNOHLLuN0co1hXWeP9v9iQ"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_youtube_icon.36.svg"
        >
          <path d="M25.714 18C25.714 18.442 25.494 18.864 25.111 19.085L14.825 25.513C14.625 25.655 14.384 25.715 14.143 25.715C13.922 25.715 13.722 25.655 13.52 25.554C13.118 25.332 12.857 24.89 12.857 24.428L12.857 11.572C12.857 11.11 13.118 10.667 13.52 10.446C13.922 10.226 14.423 10.226 14.825 10.487L25.111 16.914C25.494 17.136 25.714 17.557 25.714 18ZM36 18C36 15.127 36 12.073 35.376 9.281C34.935 7.313 33.368 5.866 31.479 5.645C27.02 5.143 22.5 5.143 18 5.143C13.5 5.143 8.98 5.143 4.521 5.645C2.631 5.866 1.085 7.313 0.643 9.281C0 12.073 0 15.127 0 18C0 20.872 0 23.926 0.623 26.717C1.065 28.686 2.631 30.133 4.521 30.355C8.98 30.857 13.5 30.857 18 30.857C22.5 30.857 27.02 30.857 31.479 30.355C33.368 30.133 34.935 28.686 35.357 26.717C36 23.926 36 20.872 36 18Z" />
        </svg>
      </a>
    </li>
    <li className="item-7" data-item="i">
      <a
        className="flex-center-middle"
        title="LinkedIn"
        href="https://www.linkedin.com/company/10743/"
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        <svg
          viewBox="0 0 36 36"
          data-use="/images/site/icon_linkedin_icon.36.svg "
        >
          <path d="M28.456 36L28.456 24.305C28.456 21.516 28.406 17.929 24.612 17.929C20.761 17.929 20.172 20.966 20.172 24.102L20.172 36L12.778 36L12.778 11.953L19.876 11.953L19.876 15.237L19.975 15.237C20.961 13.347 23.375 11.353 26.975 11.353C34.468 11.353 35.851 16.335 35.851 22.81L35.851 36L28.456 36ZM4.441 8.664C2.069 8.664 0.149 6.723 0.149 4.33C0.149 1.941 2.069 0 4.441 0C6.807 0 8.728 1.941 8.728 4.33C8.728 6.723 6.807 8.664 4.441 8.664ZM8.138 36L0.739 36L0.739 11.953L8.138 11.953L8.138 36Z" />
        </svg>
      </a>
    </li>
  </ul>
)

const FooterContacts: React.FC<{ tel: string }> = ({ tel }) => (
  <div className="contact-info">
    <div className="main flex-middle-between-wrap-block-500">
      <div className="bottom-links flex-start-wrap">
        <a className="flex-middle" href={`tel:${tel}`}>
          <svg
            viewBox="0 0 36 36"
            data-use="/images/site/gp_5z706i8p.36.svg#phone"
          >
            <path d="M35.617 30.243a9.43 9.43 0 0 1-8.38 5.744c-0.459 0-7.602 0.379-17.87-9.358-8.264-7.839-9.273-16.268-9.358-17.87-0.086-1.56 0.361-5.474 5.75-8.381 0.667-0.361 1.99-0.52 2.33-0.178 0.151 0.151 4.67 7.586 4.788 7.833a1.662 1.662 0 0 1 0.178 0.726a2.114 2.114 0 0 1-0.726 1.274a12.375 12.375 0 0 1-1.578 1.4a15.377 15.377 0 0 0-1.578 1.353a1.939 1.939 0 0 0-0.726 1.171c0.033 0.789 0.682 3.634 5.637 7.983s7.336 5.576 7.565 5.661a1.723 1.723 0 0 0 0.573 0.126a1.939 1.939 0 0 0 1.173-0.725c0.475-0.485 2.073-2.675 2.583-3.157a2.115 2.115 0 0 1 1.274-0.725a1.66 1.66 0 0 1 0.725 0.178c0.246 0.118 7.691 4.475 7.833 4.622 0.387 0.396 0.113 1.631-0.178 2.332" />
          </svg>
          <span>{tel}</span>
        </a>
        <a className="flex-middle" href="/contact-us/">
          <svg
            viewBox="0 0 36 36"
            data-use="/images/site/gp_5z706i8p.36.svg#envelope"
          >
            <path d="M18.669 22.533a1.314 1.314 0 0 1-1.339 0L0.563 12.633A0.372 0.372-8.14 0 0 0 12.953V27.992a2.831 2.831 0 0 0 2.829 2.829H33.168a2.832 2.832 0 0 0 2.832-2.829V12.953a0.373 0.373 0 0 0-0.563-0.322L18.669 22.533Zm0.075-3.103l17.062-10.073a0.37 0.37 0 0 0 0.183-0.347A2.836 2.836-8.14 0 0 33.168 6.416H2.829a2.835 2.835 0 0 0-2.819 2.592a0.371 0.371 0 0 0 0.183 0.347l17.062 10.073a1.462 1.462 0 0 0 1.489 0Z" />
          </svg>
          <span>Contact Us</span>
        </a>
        <a
          className="flex-middle"
          href="https://www.adventisthealth.org/pluskarla"
        >
          <svg
            viewBox="0 0 36 36"
            data-use="/images/site/gp_5z706i8p.36.svg#home"
          >
            <path d="M36 19.553v12.077a2.478 2.478 0 0 1-2.469 2.469H24.324a2.478 2.478 0 0 1-2.469-2.469v-8.477a2.478 2.478 0 0 0-2.469-2.469H16.613a2.478 2.478 0 0 0-2.469 2.469v8.477a2.478 2.478 0 0 1-2.469 2.469H2.469A2.478 2.478-64.8 0 1 0 31.634V19.574a2.463 2.463 0 0 1 0.749-1.78l15.724-15.198a2.474 2.474 0 0 1 3.461 0.02L35.252 17.793A2.418 2.418-64.8 0 1 36 19.553Z" />
          </svg>
          adventisthealth.org/pluskarla
        </a>
      </div>
      <FooterSocial />
    </div>
  </div>
)

const FooterSchema: React.FC = () => (
  <div
    className="schema-info hide"
    itemScope
    itemType="http://schema.org/LocalBusiness"
  >
    <meta itemProp="name" content="Adventist Health" />
    <meta itemProp="description" />
    <meta itemProp="priceRange" content="$$$" />
    <meta itemProp="url" content="http://ah.pluskarla.org" />
    <meta itemProp="image" content="/images/logo.png" />
    <meta itemProp="telephone" content="833-249-3556" />
    <div
      itemProp="address"
      itemScope
      itemType="http://schema.org/PostalAddress"
    >
      <span itemProp="streetAddress">ONE Adventist Health Way</span>
      <span itemProp="addressLocality">Roseville</span>
      <span itemProp="addressRegion">CA </span>
      <span itemProp="postalCode">95661</span>
    </div>
  </div>
)

const FooterCopyright: React.FC = () => (
  <div className="address third">
    <span>
      ©2020 - Adventist Health & Synchronous Health
      <br />
    </span>
    <span>ONE Adventist Health Way, Roseville, CA 95661</span>
  </div>
)

const Footer: React.FC<{ tel: string; menu: IMenu[] }> = ({ tel, menu }) => (
  <footer id="FooterZone">
    <section className="footer v1" id="FooterV1" data-section>
      <FooterContacts tel={tel} />
      <div className="main ">
        <FooterMainMenu menu={menu} />
      </div>
    </section>
    <section className="footer v2" id="FooterV2">
      <div className="main">
        <div className="flex-row-between-top border-top">
          <FooterCopyright />
          <FooterMenu menu={menu} />
          <FooterSchema />
        </div>
      </div>
    </section>
  </footer>
)

export default Footer
